import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { AuthContextProvider, AuthContext } from './context/AuthContext';
import 'normalize.css';
import { ViewportProvider } from './context/ViewPortContext';
import { SocketContext, socket } from './context/socket';
import { QueryClientProvider, QueryClient } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { StylesProvider } from '@material-ui/core/styles';
import generateClassName from './utils/jssSetup';
import { SuspensionProvider } from './context/SuspensionContext';

const queryClient = new QueryClient();

const Root = () => {
  const { player } = useContext(AuthContext);

  return (
    <SuspensionProvider playerId={player?.data?.player?._id}>
      <QueryClientProvider client={queryClient}>
        <SocketContext.Provider value={socket}>
          <ViewportProvider>
            <BrowserRouter>
              <StylesProvider generateClassName={generateClassName}>
                <App />
              </StylesProvider>
            </BrowserRouter>
          </ViewportProvider>
        </SocketContext.Provider>
      </QueryClientProvider>
    </SuspensionProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <Root />
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// Register the service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    navigator.serviceWorker.register('/service-worker.js').then(
      function (registration) {
        // Registration was successful
        console.log(
          'ServiceWorker registration successful with scope 2: ',
          registration.scope,
        );
      },
      function (err) {
        // registration failed :(
        console.log('ServiceWorker registration failed: ', err);
      },
    );
  });
}

// Listen for the service worker controlling the page to change
navigator.serviceWorker.addEventListener('controllerchange', () => {
  // The controlling service worker has changed, indicating a new service worker has taken control
  console.log('Controller changed. New content is available. Reloading...');
  window.location.reload();
});
