import '../sidebar/sidebar.css';
import './jgkLeaderBoard.css';
import //  RssFeed,

'@material-ui/icons';
import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import axiosConn from '../../axiosConn';
import { useQuery } from 'react-query';
import DisplayPlayerJGK from '../displayPlayerJGK/DisplayPlayerJGK';

export default function JGKLeaderBoard() {
  // const [isFetching, setIsFetching] = useState(false);
  // const [allPlayersJGK, setAllPlayersJGK] = useState([]);
  const [allPlayerCount, setAllPlayerCount] = useState(1);

  const { player } = useContext(AuthContext);

  const getAllPlayersSortJGK = async () => {
    try {
      const res = await axiosConn.get(`/players/?sort=-jgkTokens`, {
        headers: {
          Authorization: `Bearer ${player.token}`,
        },
      });
      // console.log(res.data.data.data);
      return res.data.data.data;
    } catch (err) {
      console.log(err);
    }
  };

  const { isLoading, data, isError, isSuccess } = useQuery(
    'allPlayersSortJGK',
    getAllPlayersSortJGK,
  );
  // console.log(data);

  const showMoreP = () => {
    setAllPlayerCount((prev) => prev + 1);
  };

  const showLess = () => {
    setAllPlayerCount(1); // Reset to initial number of players to show
  };

  return (
    <div className="jgkleaderboard">
      <div className="leaderBoardWrapper">
        {/* <h4 className="jgkLeaderBoardTitle">
          JGK$ Top 10 Leader Board
          <div className="tooltip">
            <InfoOutlinedIcon className="sidebarIconTooltip" fontSize="small" />
            <span className="tooltiptext">
              <p>You are a "Player" in the Joe Galaxy Game: </p>
              <p>- Your Joe Galaxy Klout is the Score.</p>
              <p>- You are reward JGK for everything you do!</p>
              <p>- Your JGK can also be cashed out for Gift Cards.</p>
              <br></br>
              <p>Stay Tuned for JG Teams or "Cubes".</p>
              <br></br>
            </span>
          </div>
        </h4> */}

        <ul className="jgkleaderboardPlayerList">
          {!isLoading &&
            data
              ?.filter((player) => {
                return (
                  player.playerId !== 'secondDayOut' &&
                  player.playerId !== 'infoJG' &&
                  player.playerId !== 'newrockstar' &&
                  player.playerId !== 'stester' &&
                  player.playerId !== 'rockstarJG' &&
                  player.playerId !== 'thirdDayDayOut' &&
                  player.playerId !== 'firstDayOut' &&
                  player.playerId !== 'jgktokenKing' &&
                  player.playerId !== 'btester' &&
                  player.playerId !== 'jgtesterone' &&
                  player.playerId !== 'winninghands' &&
                  player.playerId !== 'InterfaceOne' &&
                  player.playerId !== 'coachCarlosThree' &&
                  player.playerId !== 'FredSanFord' &&
                  player.playerId !== 'supportNET' &&
                  player.playerId !== 'jgdotnet'
                );
              })
              .slice(0, allPlayerCount)
              .map((p) => (
                <DisplayPlayerJGK key={p._id} passedInPlayerId={p.playerId} />
              ))}
          {allPlayerCount < 10 && (
            <div className="viewTopPlayers" onClick={() => showMoreP()}>
              View {10 - allPlayerCount} more Top Players
            </div>
          )}
          {allPlayerCount > 1 && (
            <div className="viewJGKTransactions" onClick={showLess}>
              Show Less
            </div>
          )}
        </ul>
      </div>
    </div>
  );
}
