import './topbarnotification.css';
import {
  Search,
  Person,
  Chat,
  // Notifications
} from '@material-ui/icons';
// import { ArrowDown } from '../../svg/arrowDown';

// import LogoutIcon from '@mui/icons-material/Logout';
// import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
// import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
// import QueuePlayNextOutlinedIcon from '@mui/icons-material/QueuePlayNextOutlined';
import { Tooltip } from '@mui/material';
import validator from 'validator';
import { Link } from 'react-router-dom';
import { useContext, useState, useEffect, useRef } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { ViewPortContext, useViewport } from '../../context/ViewPortContext';
import PlayerSearchResults from '../playerSearchResults/PlayerSearchResults';
import axiosConn from '../../axiosConn';
import useClickOutside from '../../clickOutside';
import { useMediaQuery } from 'react-responsive';
import { useGetCurrPlayer } from '../../hooks/useGetCurrPlayer';
// import PlayerMenu from './playerMenu';
import { SocketContext } from '../../context/socket';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import { useGetConversations } from '../../hooks/useGetConversations';

export default function Topbar(parent) {
  const { player } = useContext(AuthContext);
  const { width } = useContext(ViewPortContext);

  // console.log(width);
  //const [player1, setPlayer1] = useState(player);
  const [numFollowers] = useState(player.data.player.followers.length);
  const [numFriendRequests, setNumFriendRequests] = useState(
    player.data.player.requests.length,
  );
  const [friendRequestPlayers, setFriendRequestPlayers] = useState([]);
  const [conversations, setConversations] = useState([]);
  const searchInput = useRef();
  const [isSearching, setIsSearching] = useState(false);

  const [searchedPlayers, setSearchedPlayers] = useState([]);
  const [currPlayer, setCurrPlayer] = useState({});

  const [playerSearchResults, setPlayerSearchResults] = useState(false);
  const [showPlayerMenu, setShowPlayerMenu] = useState(false);
  const [showFriendRequestPlayersMenu, setShowFriendRequestPlayersMenu] =
    useState(false);
  const socket2 = useContext(SocketContext);
  const [totalUnReadMessages, setTotalUnReadMessages] = useState(0);
  const menu = useRef(null);
  const friendRequestPlayersMenu = useRef(null);
  const playermenu = useRef(null);

  // const [showUnReadMessages, setShowUnReadMessages] = useState(true);

  // const Logoff = () => {
  //   socket2.close();
  //   localStorage.clear();
  //   window.location.href = '/login';
  // };

  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });
  //console.log(parent.parent);

  const handleClickOutside = () => {
    setPlayerSearchResults(false);
    searchInput.current.value = '';
  };

  useClickOutside(menu, () => handleClickOutside());

  useClickOutside(friendRequestPlayersMenu, () => {
    setShowFriendRequestPlayersMenu(false);
  });

  useClickOutside(playermenu, () => {
    setShowPlayerMenu(false);
  });

  //1. Get currentPlayer. currentPlayer.requests[]
  const onSuccess = (data) => {
    // console.log('data: ', data);
  };

  const onError = (err) => {
    // console.log('err: ', err);
  };

  const { data, status } = useGetCurrPlayer(onSuccess, onError, player);
  // console.log(data);

  useEffect(() => {
    let mounted = true;
    //set conversations
    if (status === 'success') {
      if (!mounted) return;
      setCurrPlayer(data);
    }
    return () => {
      mounted = false;
    };
  }, [status, data]);

  useEffect(() => {
    // get friendRequestPlayers
    const getFriendRequestPlayers = async () => {
      try {
        const res1 = await axiosConn.get(
          `/players/friendRequests/${player.data.player._id}`,
          {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          },
        );
        // console.log('friendRequestPlayers: ', res1.data);
        setFriendRequestPlayers(res1.data);
        setNumFriendRequests(res1.data.length);
      } catch (err) {
        console.log(err);
      }
    };
    getFriendRequestPlayers();
  }, [player.data.player._id, player.token]);

  // const handleSearch = async (searchInput) => {
  //   //Player search
  //   setPlayerSearchResults(true);

  //   try {
  //     if (searchInput === '') {
  //       //console.log('empty');
  //       setIsSearching(false);
  //       setSearchedPlayers([]);
  //     } else if (validator.isAlphanumeric(searchInput)) {
  //       //console.log(searchInput);
  //       setIsSearching(true);
  //       const res = await axiosConn.get(`/players/regex/${searchInput}`, {
  //         headers: {
  //           Authorization: `Bearer ${player.token}`,
  //         },
  //       });
  //       //console.log(res.data.data.data);
  //       setSearchedPlayers(res.data.data.data);
  //       setIsSearching(false);
  //     }
  //   } catch (err) {
  //     setIsSearching(false);
  //     setSearchedPlayers([]);
  //     console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   const getConversations = async () => {
  //     try {
  //       const res = await axiosConn.get(
  //         '/conversations/' + player.data.player._id,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${player.token}`,
  //           },
  //         },
  //       );
  //       setConversations(res.data);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   getConversations();
  // }, [player.data.player._id, player.token]);

  const onSuccess2 = (data) => {
    // console.log('data: ', data);
  };

  const onError2 = (err) => {
    // console.log('err: ', err);
  };

  const { data: data2, status: status2 } = useGetConversations(
    onSuccess2,
    onError2,
    player,
  );
  // console.log(data);

  useEffect(() => {
    let mounted = true;
    //set conversations
    if (status2 === 'success') {
      if (!mounted) return;
      setConversations(data2);
    }
    return () => {
      mounted = false;
    };
  }, [status2, data2]);

  // useEffect(() => {
  //   conversations?.map((conversation) => {
  //     //console.log(conversation.unReadMessages);
  //     setTotalUnReadMessages((prev) => prev + conversation.unReadMessages);
  //   });

  //   // setShowUnReadMessages(totalUnReadMessages > 0);
  //   return () => {
  //     setTotalUnReadMessages(0);
  //   };
  // }, [conversations]);

  useEffect(() => {
    let total = 0;
    conversations?.forEach((conversation) => {
      total += conversation.unReadMessages;
    });

    setTotalUnReadMessages(total);

    return () => {
      setTotalUnReadMessages(0);
    };
  }, [conversations]);

  //console.log(totalUnReadMessages);

  return (
    <div
      ref={friendRequestPlayersMenu}
      className={
        !mobileView
          ? 'topbarContainer'
          : parent.parent === 'Messenger'
          ? 'topbarNotificationContainerMobileChat'
          : 'topbarNotificationContainerMobile'
      }
    >
      {/* <div className={!mobileView ? 'topbarLeft' : 'topbarLeftMobile'}></div> */}
      {/* <div className="topbarCenter"></div> */}
      <div className="friendRequestPlayersSearchResults">
        {friendRequestPlayers && showFriendRequestPlayersMenu && (
          <PlayerSearchResults searchedPlayers={friendRequestPlayers} />
        )}
      </div>
      <div className="topbarNotificationRight">
        <div className="topbarNotificationIcons">
          {currPlayer?.jgkTokens > 999 && (
            <div className="topbarNotificationIconItem">
              <img
                className={
                  currPlayer?.jgkTokens > 4999
                    ? 'lilDImgTopbar'
                    : 'chirbichawImgTopbar'
                }
                //src={post.img}
                src={
                  currPlayer?.jgkTokens > 9999
                    ? 'https://res.cloudinary.com/joegalaxy/image/upload/v1690738859/logo_transparency2_sdvx5i.png'
                    : currPlayer?.jgkTokens > 4999 &&
                      currPlayer?.jgkTokens < 10000
                    ? 'https://res.cloudinary.com/joegalaxy/image/upload/v1683396920/logo_transparency4_nyioc3.png'
                    : 'https://res.cloudinary.com/joegalaxy/image/upload/v1680380660/imageedit_0_3957215681_osoxqy.png'
                }
                // width="100"
                // height="100"
                alt="Chirbichaw"
                title="Chirbichaw"
                loading="eager"
                // style={{
                //   backgroundImage: `url("https://res.cloudinary.com/joegalaxy/image/upload/q_auto/fl_progressive:steep/v1672717524/loading-gif_sfv2dl.gif")`,
                // }}
              />
            </div>
          )}
          {width > 280 &&
            {
              /* currPlayer?.jgkTokens > 999  */
            } && (
              <div className="topbarNotificationIconItem">
                <Link to={`/jgktoken`} style={{ textDecoration: 'none' }}>
                  <Tooltip
                    title={`${(
                      Math.round(parseFloat(currPlayer?.jgkTokens) * 100) / 100
                    ).toFixed(2)} jgkTokens`}
                  >
                    {
                      <CurrencyExchangeOutlinedIcon
                        fontSize={!mobileView ? 'small' : 'large'}
                      />
                    }
                  </Tooltip>
                  <span
                    className={
                      currPlayer?.jgkTokens < 9999
                        ? 'topbarNotificationIconBadgeJGK'
                        : 'topbarNotificationIconBadgeJGK10K'
                    }
                  >
                    {currPlayer?.jgkTokens &&
                      (
                        Math.round(parseFloat(currPlayer?.jgkTokens) * 100) /
                        100
                      ).toFixed(2)}
                  </span>
                </Link>
              </div>
            )}
          <div
            className="topbarNotificationIconItem"
            onClick={() => {
              setShowFriendRequestPlayersMenu((prev) => !prev);
            }}
          >
            <Tooltip title={`${numFriendRequests} friend requests`}>
              <Person fontSize={!mobileView ? 'small' : 'large'} />
            </Tooltip>
            <span className="topbarNotificationIconBadge">
              {numFriendRequests}
            </span>
          </div>
          <div className="topbarNotificationIconItem">
            <Link to={`/messenger/''`} style={{ textDecoration: 'none' }}>
              <Tooltip title={`${totalUnReadMessages} unread messages`}>
                <Chat fontSize={!mobileView ? 'small' : 'large'} />
              </Tooltip>
              <span className="topbarNotificationIconBadge">
                {totalUnReadMessages}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
