import axiosConn from '../../axiosConn';
import { useMediaQuery } from 'react-responsive';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import {
  useContext,
  useMemo,
  useEffect,
  useState,
  useCallback,
  useRef,
  // forwardRef,
} from 'react';
import DynamicHtml from '../dynamicHtml/DynamicHtml';
import './message.css';
// import { format } from 'timeago.js';
import { AuthContext } from '../../context/AuthContext';
import { DoneAllOutlined } from '@material-ui/icons';
import ReactPlayer from 'react-player/lazy';
import ModalImage from 'react-modal-image';
import preciseFormat from '../../utils/preciseFormat';
import useUIEffects from '../../utils/useUIEffects';
import useClickOutside from '../../clickOutside';
import getMobileOS from '../../utils/getMobileOs';
import { SocketContext } from '../../context/socket';

// Move config object outside of component
const playerConfig = {
  file: {
    attributes: {
      onContextMenu: (e) => e.preventDefault(),
    },
  },
};

const initialContextMenu = {
  show: false,
  x: 0,
  y: 0,
};

export default function Message({
  message,
  own,
  senderProfilePhoto = 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png',
  // currentChat,
  receiverProfilePhoto,
  // setReceiverProfilePhoto,
  from,
  onDeleteMessage,
  receiverId,
  galaxyId = null,
  isPlayerInRole = null,
  // ref,
}) {
  const { player } = useContext(AuthContext);

  const [isVideo, setIsVideo] = useState(false);
  const [isAudio, setIsAudio] = useState(false);
  const [isQAutoUrl] = useState(message.img?.includes('q_auto'));
  const [qAutoUrl, SetQAutoUrl] = useState('');
  const [messageTextIsVideo, setMessageTextIsVideo] = useState(false);
  const [audioUrl, setAudioUrl] = useState('');
  const [contextMenu, setContextMenu] = useState(initialContextMenu);
  const contextmenu = useRef(null);
  const [open, setOpen] = useState(false);
  const socket2 = useContext(SocketContext);

  const [opacity, setOpacity] = useState(1);

  const os = getMobileOS();

  const {
    handleContextMenuUI: handleContextMenu2,
    closeContextMenu,
    handleTooltip,
    fadeOut,
  } = useUIEffects();

  useClickOutside(contextmenu, () => {
    setContextMenu(initialContextMenu);
  });
  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });

  const fileExtension = useMemo(
    () => message.img?.split('.').pop().toLowerCase(),
    [message.img],
  );

  const handleDeleteMessage = async (messageId) => {
    // console.log('handleDeleteMessage', messageId, message);
    if (typeof messageId === 'undefined') {
      console.error(
        'messageId is undefined. Check the caller to ensure it passes a valid messageId.',
      );
      return; // Early return to prevent API call with undefined messageId
    }

    try {
      // Determine the API endpoint based on the `from` variable
      const apiEndpoint =
        from === 'galaxyChat'
          ? `/galaxyMessages/deleteMessage/${messageId}`
          : `/messages/deleteMessage/${messageId}`;

      const config = {
        headers: {
          Authorization: `Bearer ${player.token}`,
        },
      };

      // Conditionally add `data` to the config if `from` is 'galaxyChat'
      if (from === 'galaxyChat') {
        config.data = { senderId: message.senderId };
      }
      // console.log('apiEndpoint', apiEndpoint, config);
      const res = await axiosConn.delete(apiEndpoint, config);

      // Optionally, update UI or state here
      if (onDeleteMessage) onDeleteMessage(messageId);

      // Check if from is 'galaxyChat' and emit 'deleteGalaxyMessage' with galaxyId and messageId
      if (from === 'galaxyChat') {
        socket2.emit('deleteGalaxyMessage', {
          galaxyId: galaxyId,
          messageId: messageId,
        });
      } else {
        // For non-galaxyChat, emit 'deleteMessage'
        socket2.emit('deleteMessage', {
          receiverId: receiverId,
          messageId: messageId,
        });
      }
    } catch (err) {
      console.error(err);
    }
    // Emit event or update UI as needed
  };

  useEffect(() => {
    const event =
      from === 'galaxyChat' ? 'getGalaxyMessageDeleted' : 'getMessageDeleted';
    const handleMessageDeleted = ({ messageId }) => {
      // console.log(event, messageId);
      if (onDeleteMessage) onDeleteMessage(messageId);
    };

    socket2.on(event, handleMessageDeleted);

    return () => {
      socket2.off(event, handleMessageDeleted);
    };
  }, [onDeleteMessage, socket2, from]); // Include `from` in the dependency array

  useEffect(() => {
    let isMounted = true;
    if (message.img) {
      const fileExtension = message.img.split('.').pop().toLowerCase();

      const videoExtensions = ['mp4', 'mov'];
      const audioExtensions = ['webm', 'mp3', 'wav'];

      if (!isMounted) return;
      const isVideo = videoExtensions.includes(fileExtension);
      const isAudio = audioExtensions.includes(fileExtension);

      setIsVideo(isVideo);
      setIsAudio(isAudio);

      if (isAudio) {
        // console.log('audio detected');
        setAudioUrl(message.img.replace('webm', 'mp4'));
      }

      if (!isQAutoUrl) {
        SetQAutoUrl(
          message.img.split('/upload/')[0] +
            '/upload/q_auto/' +
            message.img.split('/upload/')[1],
        );
      }
    } else {
      if (!isMounted) return;
      setIsVideo(false);
      setIsAudio(false);
    }
    return () => {
      isMounted = false;
    };
  }, [isQAutoUrl, fileExtension]);

  // console.log(
  //   'isAudio:',
  //   isAudio,
  //   'isVideo:',
  //   isVideo,
  //   'message.img: ',
  //   message.img,
  // );

  const handleContextMenu = useCallback((e) => e.preventDefault(), []);
  // console.log('message', message);
  return (
    <div className={own ? 'message own' : 'message'}>
      <div className="messageCenter">
        {isVideo ? (
          <div style={{ width: '80%', margin: '0 auto' }}>
            {' '}
            <ReactPlayer
              key={message.img}
              autoPlay={false}
              loop={true}
              muted={true}
              width="52%"
              height="75%"
              config={playerConfig} // Use config object
              playing={true}
              controls={true}
              url={isQAutoUrl ? message.img : qAutoUrl}
              playsinline={true}
              onContextMenu={handleContextMenu} // Use callback function
            />
          </div>
        ) : isAudio ? (
          <ReactPlayer
            key={message.img}
            autoPlay={false}
            loop={false}
            muted={false}
            width="100%"
            height="75%"
            config={playerConfig} // Use config object
            playing={false}
            controls={true}
            url={audioUrl}
            playsinline={true}
            onContextMenu={handleContextMenu} // Use callback function
          />
        ) : message.img ? (
          <ModalImage
            small={message.img}
            large={message.img}
            alt=""
            className="messageImg2"
            hideDownload={true}
          />
        ) : null}
      </div>
      <div className="msgAndXBtnContainer">
        <div className="messageTop">
          {
            <DynamicHtml
              from={from === 'galaxyChat' ? 'galaxyChat' : 'message'}
              text={message.text}
              own={own}
              setMessageTextIsVideo={setMessageTextIsVideo}
              senderProfilePhoto={senderProfilePhoto}
              receiverProfilePhoto={receiverProfilePhoto}
            />
          }
        </div>
        {(own || (isPlayerInRole && from === 'galaxyChat')) && (
          <button
            className="xButton"
            onClick={() => {
              if (message._id || message.tempId) {
                handleDeleteMessage(message._id || message.tempId);
              } else {
                console.error('Message ID is undefined', message);
              }
            }}
            title="Delete message"
          >
            x
          </button>
        )}
      </div>
      <div
        className={
          !mobileView
            ? messageTextIsVideo
              ? 'messageBottomMsgVideo'
              : 'messageBottom'
            : messageTextIsVideo
            ? 'messageBottomMobileMsgVideo'
            : 'messageBottomMobile'
        }
      >
        {preciseFormat(message.createdAt)}{' '}
        {own && from !== 'galaxyChat' ? (
          message.isRead ? (
            <DoneAllOutlinedIcon sx={{ color: 'lightgrey', fontSize: 18 }} />
          ) : (
            <DoneOutlinedIcon sx={{ color: 'lightgrey', fontSize: 18 }} />
          )
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
