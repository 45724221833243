import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useGetGalaxies } from '../../hooks/useGetGalaxies';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import { AuthContext } from '../../context/AuthContext';
import Topbar from '../../components/topbar/Topbar';
import Sidebar from '../../components/sidebar/Sidebar';
import Rightbar from '../../components/rightbar/Rightbar';
import { useMediaQuery } from 'react-responsive';
import TopbarNotification from '../../components/topbarNotification/TopbarNotification';
import CreateGalaxyForm from '../../components/createGalaxyForm/CreateGalaxyForm';
import './galaxy.css';
import { useGetConversations } from '../../hooks/useGetConversations';
import GalaxyRightBarMobile from '../../components/galaxyRightbarMobile/GalaxyRightbarMobile';
import useMobileView from '../../hooks/useMobileView';

export default function Galaxies() {
  const [page, setPage] = useState(1);
  const [galaxyItems, setGalaxyItems] = useState([]);
  const [showForm, setShowForm] = useState(false); // New state for the form visibility
  const [conversations, setConversations] = useState([]);
  const [totalUnReadMessages, setTotalUnReadMessages] = useState(0);
  const [jgkTokenSum, setJgkTokenSum] = useState(0);
  const [galaxyChatOnlinePlayers, setGalaxyChatOnlinePlayers] = useState([]);

  const { player } = useContext(AuthContext);
  const mobileView = useMobileView();

  const handleJgkTokenSum = useCallback((sum) => {
    setJgkTokenSum(sum);
  }, []); // No dependencies, as setJgkTokenSum is stable

  const onSuccess = (data) => {
    // console.log('data: ', data);
  };

  const onError = (err) => {
    // console.log('err: ', err);
  };

  //   console.log(page);

  const { data, status } = useGetGalaxies(onSuccess, onError, player, page);

  if (status === 'success' && data) {
    // console.log('data: ', data.data.data);
  }
  const loadMore = () => {
    setPage((old) => old + 1);
  };

  // Use the custom hook
  useInfiniteScroll(loadMore);

  useEffect(() => {
    let mounted = true;
    if (status === 'success' && data) {
      const galaxies = data.data.data; // Access the array of galaxies
      if (Array.isArray(galaxies)) {
        setGalaxyItems((prevGalaxies) => {
          const existingGalaxyIds = prevGalaxies.map((galaxy) => galaxy._id);
          const newGalaxies = galaxies.filter(
            (galaxy) => !existingGalaxyIds.includes(galaxy._id),
          );
          if (mounted) {
            const sortedGalaxies = [...prevGalaxies, ...newGalaxies].sort(
              (a, b) => {
                // Sort by marketValue first
                const marketValueA = Number(a.marketValue) || 0;
                const marketValueB = Number(b.marketValue) || 0;
                if (marketValueB !== marketValueA) {
                  return marketValueB - marketValueA;
                }
                // If marketValue is the same, sort by subscribers
                const subscribersA = a.subscribers?.length || 0;
                const subscribersB = b.subscribers?.length || 0;
                return subscribersB - subscribersA;
              },
            );

            return sortedGalaxies;
          }
          return prevGalaxies;
        });
      }
    }
    return () => {
      mounted = false;
    };
  }, [data, status]);

  const onSuccess2 = (data) => {
    // console.log('data: ', data);
  };

  const onError2 = (err) => {
    // console.log('err: ', err);
  };

  const {
    data: conversationsData,
    isSuccess,
    status: conversationStatus,
    refetch,
  } = useGetConversations(onSuccess2, onError2, player);
  // console.log(data);

  useEffect(() => {
    //set conversations
    if (isSuccess) {
      // console.log(isSuccess);
      setConversations(conversationsData);
    }
  }, [conversationsData, isSuccess]);

  //   console.log(galaxyItems);
  return (
    <>
      <Topbar
        conversationsData={conversationsData}
        conversationsStatus={conversationStatus}
        refetchConversations={refetch}
        parent="Galaxy"
        conversations={conversations}
        setConversations={setConversations}
        totalUnReadMessages={totalUnReadMessages}
        setTotalUnReadMessages={setTotalUnReadMessages}
      />
      {mobileView && <TopbarNotification />}
      <div className="homeContainer">
        {!mobileView && <Sidebar />}
        <div className="feed">
          <button
            className="postButtonRank"
            onClick={() => setShowForm(!showForm)}
          >
            {showForm ? 'Hide' : 'Create Galaxy'}
          </button>
          {showForm &&
            (player.data.player.role !== 'admin' &&
            player.data.player.role !== 'galaxyAdmin' ? (
              <div className="centerContainer">
                <div className="errorText">
                  You must be a galaxyAdmin or admin to create a galaxy. Send a
                  chat message to joegalaxy to request.
                </div>
              </div>
            ) : (
              <div className="container">
                <div className="centerContent">
                  <CreateGalaxyForm />
                </div>
              </div>
            ))}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ul
              style={{
                listStyleType: 'none',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start', // Align the cards to the left
              }}
            >
              {galaxyItems.map((galaxy) => (
                <li key={galaxy._id}>
                  <div
                    style={{
                      margin: '10px',
                      padding: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '5px',
                      width: '80%',
                    }}
                  >
                    <Link
                      to={{
                        pathname: `/galaxies/${galaxy._id}`,
                      }}
                    >
                      <h2>{galaxy.name}</h2>
                      {/* <GalaxyRightBarMobile
                        onJgkTokenSumChange={handleJgkTokenSum}
                        galaxy={galaxy}
                        parent="galaxyPage"
                      /> */}
                      <p>
                        Market Value:{' '}
                        <strong className="highlighted">
                          JGK
                          {Math.floor(Number(galaxy.marketValue))
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </strong>
                      </p>{' '}
                      <p>Subscribers: {galaxy.members?.length}</p>
                      <p>Location: {galaxy.location}</p>
                      <p>Contact Info: {galaxy.contactInfo}</p>
                      <p>Average Rating: {galaxy.ratingsAverage}</p>
                      <p>Number of Ratings: {galaxy.ratingsQuantity}</p>
                      <p>Summary: {galaxy.summary}</p>
                      <button className="loginButton">
                        Go to Galaxy {galaxy.name}
                      </button>
                    </Link>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {!mobileView && <Rightbar />}
      </div>
    </>
  );
}
