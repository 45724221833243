// ShareJGButton.jsx
import React, { useRef } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const ShareJGButton = ({
  pogw,
  handleShareJGToggle,
  shareJG,
  mobileView,
  width,
  color,
}) => {
  return (
    <div className="shareOption">
      <IconButton
        disabled={pogw}
        onClick={handleShareJGToggle}
        aria-label="shareJG"
        size="medium"
        style={{ color: shareJG === 1 || pogw ? 'black' : color }}
      >
        <ShareOutlinedIcon size="small" />
        {!mobileView && <span className="shareOptionText">Share JG</span>}
      </IconButton>
      {width > 280 && !pogw && (
        <div className="tooltip">
          <InfoOutlinedIcon
            className="sidebarIconTooltip"
            fontSize="small"
            style={{ color: shareJG === 1 ? 'black' : color }}
          />
          <span className="tooltiptext">
            <p>Share JG: </p>
            <p>- Invite your friends.</p>
            <p>- You Receive 20 JGK Credits for the Invite.</p>
            <p>- You Receive 100 JGK Credits when your friend Registers!</p>
            <br></br>
            <p>
              Enter your friends Name & Email Address and JG will make sure you
              get the JGK Credits. 120 JGK Total!
            </p>
            <br></br>
          </span>
        </div>
      )}
    </div>
  );
};

export default ShareJGButton;
