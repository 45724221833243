import axiosConn from '../../axiosConn';
import { useMediaQuery } from 'react-responsive';
import { CircularProgress } from '@material-ui/core';
import { Tooltip } from '@mui/material';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { useEffect, useState, useRef } from 'react';
import './conversation.css';
import { snakeAudio, dreamerAudio } from '../../context/socket';
import ContextMenu from '../../components/conversations/contextMenu/ContextMenu';
import useClickOutside from '../../clickOutside';
import getMobileOS from '../../utils/getMobileOs';
import useUIEffects from '../../utils/useUIEffects';

const initialContextMenu = {
  show: false,
  x: 0,
  y: 0,
};

export default function Conversation({
  onlinePlayers,
  conversation,
  currentPlayer,
  setMessages,
  setCurrentChat,
  currentChat,
  arrivalMessage,
}) {
  const [player, setPlayer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOnline, setIsOnline] = useState(false);
  const [fwiendId, setFwiendId] = useState(null);
  const [contextMenu, setContextMenu] = useState(initialContextMenu);
  const [open, setOpen] = useState(false);
  const [showUnReadMessages, setShowUnReadMessages] = useState(false);
  const [opacity, setOpacity] = useState(1);
  const [arrivalMessageUnRead, setArrivalMessageUnRead] = useState(false);
  // Inside your component
  const currentPage = window.location.pathname;
  //const [showPlayerMenu, setShowPlayerMenu] = useState(false);
  const [isPreviouslyOnline, setIsPreviouslyOnline] = useState(false);
  const { handleContextMenuUI, closeContextMenu, handleTooltip, fadeOut } =
    useUIEffects();

  const contextmenu = useRef(null);

  useClickOutside(contextmenu, () => {
    setContextMenu(initialContextMenu);
  });

  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });

  const os = getMobileOS();
  // console.log(os);

  useEffect(() => {
    let mounted = true;
    const friendId = conversation.members.find(
      (m) => m !== currentPlayer.data.player._id,
    );
    setFwiendId(friendId);

    const getPlayer = async () => {
      try {
        setIsLoading(true);

        const res = await axiosConn(`/players/${friendId}`, {
          headers: {
            Authorization: `Bearer ${currentPlayer.token}`,
          },
        });
        if (!mounted) return;
        setPlayer(res.data.data.data);
      } catch (err) {
        console.error(err);
        // Add more robust error handling here
      } finally {
        if (!mounted) return;

        setIsLoading(false);
      }
    };

    if (friendId) {
      getPlayer();
    }
    return () => {
      mounted = false;
    };
  }, [
    // currentPlayer.data.player._id,
    // currentPlayer.token,
    conversation.members,
  ]);

  useEffect(() => {
    setShowUnReadMessages(conversation.unReadMessages > 0);
  }, [conversation.unReadMessages]);

  useEffect(() => {
    // if there is an arrivalMessage for this conversation
    // right now and this conversation is not the currentChat.
    // show the unReadNotification (red dot).
    if (
      arrivalMessage &&
      conversation?.members.includes(arrivalMessage.sender) &&
      Date.now() - arrivalMessage.createdAt < 1000 &&
      conversation?._id !== currentChat?._id
    )
      setShowUnReadMessages(true);
    else {
      // console.log('');
      // setShowUnReadMessages(false);
    }
    return () => {
      // setShowUnReadMessages(false);
    };
  }, [
    arrivalMessage,
    conversation?._id,
    conversation?.members,
    currentChat?._id,
  ]);

  const playAudio = async (audio) => {
    // Stop all other audio elements
    [dreamerAudio, snakeAudio].forEach((audioElement) => {
      if (audioElement !== audio && !audioElement.paused) {
        audioElement.pause();
        audioElement.currentTime = 0; // Optional: reset audio playback to the start
      }
    });

    // Ensure the audio is paused before trying to play it
    if (audio.paused) {
      try {
        await audio.play();
        // Automatic playback started!
        // Additional actions if needed
      } catch (error) {
        // Auto-play was prevented
        // Additional actions if needed
        console.log(error);
      }
    }
  };

  // console.log(window.location.pathname);

  useEffect(() => {
    let mounted = true;
    const isCurrentlyOnline = onlinePlayers.includes(fwiendId);

    if (currentPage.includes('galaxies')) {
      // Skip playing audio
    } else if (isPreviouslyOnline !== isCurrentlyOnline) {
      // console.log('playing audio');
      if (isCurrentlyOnline) {
        playAudio(snakeAudio);
      } else {
        playAudio(dreamerAudio);
      }
    }

    if (mounted) {
      setIsPreviouslyOnline(isCurrentlyOnline);
    }

    return () => {
      mounted = false;
    };
  }, [currentPage, fwiendId, onlinePlayers]);

  // console.log(arrivalMessage, Date.now() - arrivalMessage?.createdAt);
  return (
    <div className="conversation">
      {isLoading ? (
        <div>
          <CircularProgress color="inherit" size="10px" />
        </div>
      ) : (
        <>
          <Tooltip
            open={open}
            onClose={() => handleTooltip(setOpen).close()}
            onOpen={() => handleTooltip(setOpen).open()}
            title={`Right Click for ${player?.playerId} Chat Options.`}
          >
            <div
              className={`${contextMenu?.show && 'active_header'}`}
              ref={contextmenu}
              style={{ opacity: opacity }}
            >
              <div
                className="chatOnlineImgContainer"
                onContextMenu={(e) => {
                  handleContextMenuUI(e, setContextMenu, setOpacity, fadeOut);
                  handleTooltip(setOpen).close();
                }}
                onClick={(e) => {
                  setShowUnReadMessages(false);
                  os === 'iOS'
                    ? handleContextMenuUI(
                        e,
                        setContextMenu,
                        setOpacity,
                        fadeOut,
                      )
                    : !contextMenu?.show && setCurrentChat(conversation);
                }}
              >
                <img
                  className={
                    !mobileView ? 'conversationImg' : 'conversationImgMobile'
                  }
                  src={
                    player?.profilePhoto
                      ? player.profilePhoto
                      : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                  }
                  alt=""
                  title={'Right Click for Options.'}
                />
                {conversation.unReadMessages > 0 && showUnReadMessages && (
                  <span className="unReadMessagesIconBadge">
                    {conversation.unReadMessages > 0
                      ? conversation.unReadMessages
                      : null}
                  </span>
                )}
                {showUnReadMessages && (
                  <span
                    onClick={() => {
                      setShowUnReadMessages(false);
                    }}
                    className="unReadMessagesIconBadge"
                  >
                    {'...'}
                  </span>
                )}
                {isOnline && (
                  <div
                    className={
                      !mobileView
                        ? 'conversationOnlineBadge'
                        : 'conversationOnlineBadgeMobile'
                    }
                  ></div>
                )}

                {contextMenu?.show && (
                  <ContextMenu
                    xPos={contextMenu.x}
                    yPos={contextMenu.y}
                    closeContextMenu={() => closeContextMenu(setContextMenu)}
                    conversation={conversation}
                    handleTooltipClose={() => handleTooltip(setOpen).close()}
                    setMessages={setMessages}
                    os={os}
                    setCurrentChat={setCurrentChat}
                    setShowUnReadMessages={setShowUnReadMessages}
                    fwiend={player}
                  />
                )}
              </div>
            </div>
          </Tooltip>
        </>
      )}
    </div>
  );
}
