import { useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

import Topbar from '../../components/topbar/Topbar';
import Sidebar from '../../components/sidebar/Sidebar';
import Rightbar from '../../components/rightbar/Rightbar';
import CreateMeme from '../../components/createMeme/CreateMeme';
import { useMediaQuery } from 'react-responsive';
import { useQuery } from 'react-query';
import { useGetMemes } from '../../hooks/useGetMemes';
import Meme from '../../components/meme/Meme';

import axios from 'axios';
import axiosConn from '../../axiosConn';

import './meme.css';
import { SocketContext } from '../../context/socket';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import { useGetConversations } from '../../hooks/useGetConversations';

export default function MemePage() {
  const [isFetching, setIsFetching] = useState(false);
  const [memeItems, setMemeItems] = useState([]);
  const [memeCount, setMemeCount] = useState(1);
  const [sortBy, setSortBy] = useState(0);
  const [page, setPage] = useState(1);
  const [forceUpdate, setForceUpdate] = useState(0);
  const [socketMemeIds, setSocketMemeIds] = useState([]);
  const { player } = useContext(AuthContext);
  const socket2 = useContext(SocketContext);
  const [newMemes, setNewMemes] = useState([]);
  const newMemesRef = useRef([]);
  const [conversations, setConversations] = useState([]);
  const [totalUnReadMessages, setTotalUnReadMessages] = useState(0);

  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });

  //converted to react query from useState api call.
  // const getMemes = async () => {
  //   try {
  //     const res = await axiosConn.get('/memes/getAllMemesComments', {
  //       headers: {
  //         Authorization: `Bearer ${player.token}`,
  //       },
  //     });
  //     // console.log('memeItems: ', res.data);
  //     return res.data.sort((a, b) => {
  //       return new Date(b.createdAt) - new Date(a.createdAt);
  //     });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  const onSuccess = (data) => {
    // console.log('data: ', data);
  };

  const onError = (err) => {
    // console.log('err: ', err);
  };

  const { isLoading, data, status } = useGetMemes(
    onSuccess,
    onError,
    player,
    page,
  );

  // useEffect(() => {
  //   let mounted = true;
  //   if (status === 'success' && data) {
  //     setMemeItems((prevMemes) => {
  //       const existingMemeIds = prevMemes.map((meme) => meme._id);
  //       const newMemes = data.filter(
  //         (meme) => !existingMemeIds.includes(meme._id),
  //       );
  //       // Check if the component is still mounted before updating the state
  //       if (mounted) {
  //         return [...prevMemes, ...newMemes].sort(
  //           (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
  //         );
  //       }
  //       return prevMemes;
  //     });
  //   }
  //   return () => {
  //     mounted = false;
  //   };
  // }, [data, status]);
  // console.log(data);

  useEffect(() => {
    let mounted = true;
    if (status === 'success' && mounted && data) {
      // Check that data is defined
      if (data.length > 0) {
        setMemeItems((prevMemes) => {
          const existingMemeIds = prevMemes.map((meme) => meme._id);
          const newMemes = data.filter(
            (meme) =>
              !existingMemeIds.includes(meme._id) &&
              !socketMemeIds.includes(meme._id), // Add socketMemeIds logic
          );
          return [...prevMemes, ...newMemes].sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
          );
        });
      } else {
        console.log('No more memes to load.');
      }
    }
    return () => {
      mounted = false;
    };
  }, [status, data, socketMemeIds]); // Add socketMemeIds to the dependency array

  const onSuccess2 = (data) => {
    // console.log('data: ', data);
  };

  const onError2 = (err) => {
    // console.log('err: ', err);
  };

  const {
    data: conversationsData,
    isSuccess,
    status: conversationStatus,
    refetch,
  } = useGetConversations(onSuccess2, onError2, player);
  // console.log(data);

  useEffect(() => {
    //set conversations
    if (isSuccess) {
      // console.log(isSuccess);
      setConversations(conversationsData);
    }
  }, [conversationsData, isSuccess]);

  // Call handleScroll when the user scrolls up
  useInfiniteScroll(() => {
    handleScroll();
  }, 'up');

  // Call setPage when the user scrolls down
  useInfiniteScroll(() => {
    setPage((oldPage) => oldPage + 1);
  }, 'down');

  // const showMore = () => {
  //   setMemeCount((prev) => prev + 1);
  // };

  const handleSortTopRanked = () => {
    const sortedMemeItems = [...memeItems].sort((a, b) => {
      return b.likes?.length - a.likes?.length;
    });
    setMemeItems(sortedMemeItems);
    setSortBy(1);
    setMemeCount(1);
    //console.log(sortedMemeItems);
  };

  const handleSortNewest = () => {
    // console.log('handleSortNewest');
    const sortedMemeItems = [...memeItems].sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    setMemeItems(sortedMemeItems);
    setSortBy(0);
    setMemeCount(1);

    // console.log(sortedMemeItems);
  };

  const memeComponents = memeItems?.slice(0, page * 2).map((m, i) => {
    return <Meme meme={m} key={m._id + i} />;
  });

  useEffect(() => {
    socket2.on('getMeme', (meme) => {
      setSocketMemeIds((prev) => [...prev, meme._id]);

      newMemesRef.current = [...newMemesRef.current, meme];
    });

    return () => {
      socket2.off('getMeme');
    };
  }, [player.data.player.playerId, socket2]);

  useEffect(() => {
    handleScroll();
  }, [newMemesRef.current]);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    // Get the first meme element
    const firstMeme = document.querySelector('.post'); // Using the 'post' class as the selector

    // Calculate the position of the bottom of the first meme relative to the top of the page
    const firstMemeBottom = firstMeme
      ? firstMeme.getBoundingClientRect().top +
        firstMeme.getBoundingClientRect().height +
        scrollTop
      : 0;

    if (scrollTop <= firstMemeBottom && newMemesRef.current.length > 0) {
      setMemeItems((prevMemes) => {
        const existingMemeIds = prevMemes.map((meme) => meme._id);
        const uniqueNewMemes = newMemesRef.current.filter(
          (meme) => !existingMemeIds.includes(meme._id),
        );

        setTimeout(() => {
          newMemesRef.current = [];
        }, 0);

        return [...uniqueNewMemes, ...prevMemes].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
        );
      });
    }
  };

  return (
    <>
      {/* <div key={forceUpdate}> */}
      {/* <Topbar /> */}
      <Topbar
        conversationsData={conversationsData}
        conversationsStatus={conversationStatus}
        refetchConversations={refetch}
        parent="Meme"
        conversations={conversations}
        setConversations={setConversations}
        totalUnReadMessages={totalUnReadMessages}
        setTotalUnReadMessages={setTotalUnReadMessages}
      />
      <div className="memeContainer">
        {!mobileView && <Sidebar />}
        {/* <div className="spacerForPage"></div> */}

        <div className="feedMeme">
          <CreateMeme setMemeItems={setMemeItems} />

          <div className="feedWrapper">
            <h4 className="memeTitle">
              Meme Machine {sortBy === 0 ? 'Newest' : 'Top Rank'}
            </h4>
            <div
              className={
                !mobileView ? 'memePostToggleMeme' : 'memePostToggleMobile'
              }
            >
              {/* <button
                className="postLeftButtonRank"
                onClick={sortBy === 0 ? handleSortTopRanked : handleSortNewest}
                id="sort"
              >
                {sortBy === 0 ? 'Top Ranked' : 'Newest'}
              </button> */}
              {mobileView && (
                <button
                  className="postButtonRank"
                  onClick={
                    sortBy === 0 ? handleSortTopRanked : handleSortNewest
                  }
                  id="sort"
                >
                  <Link
                    to="/"
                    style={{ textDecoration: 'none' }}
                    className="memeLinkItem"
                  >
                    Galaxy Posts
                  </Link>
                </button>
              )}
            </div>
            {isLoading ? (
              <div className="feedPostProgress">
                <CircularProgress color="inherit" size="10px" />
              </div>
            ) : (
              !isLoading && memeComponents
            )}
          </div>
        </div>
        {!mobileView && <Rightbar />}
      </div>
      {/* </div> */}
    </>
  );
}
