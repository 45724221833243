import './galaxyProfile.css';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axiosConn from '../../axiosConn';
import EasyEdit from 'react-easy-edit';
import { Link } from 'react-router-dom';
import { SocketContext } from '../../context/socket';
import { useQuery } from 'react-query';
import GalaxyRightBarMobile from '../../components/galaxyRightbarMobile/GalaxyRightbarMobile';
import { ViewPortContext } from '../../context/ViewPortContext';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import WelcomeMessage from '../../components/welcomeMessage/WelcomeMessage'; // Adjust the import path as necessary

import Topbar from '../../components/topbar/Topbar';
import TopbarNotification from '../../components/topbarNotification/TopbarNotification';

import Sidebar from '../../components/sidebar/Sidebar';
import Feed from '../../components/feed/Feed';
import GalaxyRightBar from '../../components/galaxyRightbar/GalaxyRightbar';
import { AuthContext } from '../../context/AuthContext';
import { CircularProgress } from '@material-ui/core';
import { Button } from '@material-ui/core';

import { handleYouTube } from '../../utils/youtubeUtil';
import { handleProfileAndCoverUpload } from '../../utils/handleProfileAndCoverUpload';
import LoadingBar from 'react-top-loading-bar';
import GalaxyChatBox from '../../components/galaxyChatBox/GalaxyChatBox';
import DisplayPlayerJGK from '../../components/displayPlayerJGK/DisplayPlayerJGK';
import MediaCover from '../../components/mediaCover/MediaCover';
import useMobileView from '../../hooks/useMobileView';
import { useGetConversations } from '../../hooks/useGetConversations';
import AdRotator from '../../components/adRotator/AdRotator';
import playSound from '../../utils/playSound';
// import useStyles from '../../utils/useStyles';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  buttonSubscribed: {
    backgroundColor: '#8a2be2',
    color: 'white',
  },
  buttonDefault: {
    backgroundColor: '#3f51b5',
    color: 'white',
  },
  tooltip: {
    fontSize: theme.typography.pxToRem(18),
  },
  parentContainer: {
    height: '100vh', // or any specific height you need
    display: 'flex',
    flexDirection: 'column',
  },
  adRotator: {
    flex: '1 1 auto', // This ensures the AdRotator takes up available space
    overflow: 'hidden', // Prevents overflow
  },
}));

const AD_DURATION = 10;
const enterGalaxyChatSound =
  'https://res.cloudinary.com/joegalaxy/video/upload/v1715314959/rizz-sound-effect_nbmdgm.mp3';

// Initialize the audio object outside of the useEffect
const notificationSound = new Audio(
  'https://res.cloudinary.com/joegalaxy/video/upload/v1725333477/winner-bell-game-show-91932_skw7gc.mp3',
);

export default function GalaxyProfile() {
  const { player } = useContext(AuthContext);
  const { galaxyId } = useParams();
  const [galaxy, setGalaxy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [isTube, setIsTube] = useState(false);
  // Calculate the sum of the market value of all subscribers
  const [jgkTokenSum, setJgkTokenSum] = useState(0);
  const coverInputRef = useRef();
  const [isFetching, setIsFetching] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const socket = useContext(SocketContext);
  const [galaxyChatOnlinePlayers, setGalaxyChatOnlinePlayers] = useState([]);
  const [subscriptionStatus, setSubscriptionStatus] = useState('Subscribe');
  const [subscriptionRequests, setSubscriptionRequests] = useState([]);
  const [requestHandled, setRequestHandled] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const mobileView = useMobileView();
  const { width } = useContext(ViewPortContext);
  const [showMoreGalaxyInfo, setShowMoreGalaxyInfo] = useState(false);
  const [mmenuOpen, setMmenuOpen] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [totalUnReadMessages, setTotalUnReadMessages] = useState(0);
  const [showAd, setShowAd] = useState(true);
  const [countdown, setCountdown] = useState(0);
  const [showVideo, setShowVideo] = useState(false); // New state for the form visibility
  const [showGalaxyChat, setShowGalaxyChat] = useState(false);
  // const [isFading, setIsFading] = useState(false);
  const classes = useStyles();

  const isRoleIncluded = ['moderator', 'galaxyAdmin', 'admin'].includes(
    player.data.player.role,
  );
  const isPlayerInRole =
    ((galaxy?.owners || []).some(
      (owner) => owner._id === player.data.player._id,
    ) ||
      (galaxy?.administrators || []).some(
        (admin) => admin._id === player.data.player._id,
      ) ||
      (galaxy?.moderators || []).some(
        (moderator) => moderator._id === player.data.player._id,
      )) &&
    isRoleIncluded;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (showAd) {
      if (!mounted) return;
      setCountdown(AD_DURATION); // Replace AD_DURATION with the duration of the ad in seconds
      const timerId = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            // Stop the countdown and hide the ad when the countdown reaches zero
            clearInterval(timerId);
            setShowAd(false);
            return 0;
          } else {
            return prevCountdown - 1;
          }
        });
      }, 1000);
      return () => {
        clearInterval(timerId); // Clean up the interval on unmount
        mounted = false;
      };
    }
  }, [showAd]);

  const onSuccess2 = (data) => {
    // console.log('data: ', data);
  };

  const onError2 = (err) => {
    // console.log('err: ', err);
  };

  const {
    data: conversationsData,
    isSuccess,
    status: conversationStatus,
    refetch,
  } = useGetConversations(onSuccess2, onError2, player);
  // console.log(data);

  useEffect(() => {
    let mounted = true;
    //set conversations
    if (isSuccess) {
      // console.log(isSuccess);
      if (!mounted) return;
      setConversations(conversationsData);
    }
    return () => {
      mounted = false;
    };
  }, [conversationsData, isSuccess]);

  useEffect(() => {
    let mounted = true;
    // If the width is greater than or equal to 807, set showMoreGalaxyInfo to true
    if (width >= 807) {
      if (!mounted) return;
      setShowMoreGalaxyInfo(true);
    } else {
      if (!mounted) return;
      setShowMoreGalaxyInfo(false);
    }
    return () => {
      mounted = false;
    };
  }, [width]); // Empty dependency array means this effect runs once on component mount
  const handleShowMoreGalaxyInfo = () => {
    // setIsFading(true);
    // setTimeout(() => setIsFading(false), 1000);
    setShowMoreGalaxyInfo((prev) => !prev);
    // console.log('Show more galaxy info:', showMoreGalaxyInfo);
  };

  const handleClick = (tag) => (event) => {
    if (tag === 'cover') coverInputRef.current.click();
    // else imageUploader.current.click();
  };

  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };

  const handleJgkTokenSum = async (sum) => {
    // Check if player is not in galaxy members
    if (!galaxy.members.includes(player.data.player._id)) {
      console.log('Player is not a member of this galaxy.');
      return; // Exit the function if player is not in galaxy members
    }
    // Only make the PATCH request if the sum has changed
    setJgkTokenSum(sum);
    if (sum !== galaxy.marketValue) {
      // console.log('JGK Token Sum:', sum);
      const update = { marketValue: sum };
      // console.log('Updating galaxy market value:', update, galaxyId);
      try {
        // console.log('Updating galaxy market value:', update);
        const response = await axiosConn.patch(
          `/galaxies/${galaxyId}`,
          update,
          {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          },
        );
        // if (response.status === 200) {
        //   setGalaxy((prevState) => ({
        //     ...prevState,
        //     marketValue: sum,
        //   }));
        // }
      } catch (error) {
        console.error('Failed to update galaxy market value: ', error);
      }
    }
  };

  const handleGalaxySave = (field) => async (value) => {
    const update = { [field]: value };
    // console.log(update);
    try {
      const response = await axiosConn.patch(`/galaxies/${galaxyId}`, update, {
        headers: {
          Authorization: `Bearer ${player.token}`,
        },
      });
      // console.log(response);
      if (response.status === 200) {
        setGalaxy((prevState) => ({
          ...prevState,
          [field]: value,
        }));
      }
    } catch (error) {
      console.error('Failed to update galaxy: ', error);
    }
  };

  const fetchGalaxy = async () => {
    const response = await axiosConn.get(`/galaxies/${galaxyId}`, {
      headers: {
        Authorization: `Bearer ${player.token}`,
      },
    });
    return response.data.data.data;
  };

  const {
    data: fetchedGalaxy,
    isLoading,
    error,
  } = useQuery(['galaxy', galaxyId, player.token], fetchGalaxy);
  // useEffect(() => {
  //   console.log('Is loading:', isLoading);
  // }, [isLoading]);

  useEffect(() => {
    let mounted = true;
    if (fetchedGalaxy) {
      // console.log(
      //   'Fetched galaxy:',
      //   fetchedGalaxy.requests,
      //   subscriptionStatus,
      //   player.data.player.role,
      // );
      if (!mounted) return;
      setGalaxy(fetchedGalaxy);
      setSubscriptionRequests(fetchedGalaxy.requests);
    }
    return () => {
      mounted = false;
    };
  }, [fetchedGalaxy]);

  useEffect(() => {
    let mounted = true;
    const { _id } = player.data.player;

    // Check if galaxy and player are not null
    if (galaxy && player) {
      const { administrators, moderators, owners } = galaxy;

      // Check if the player is an administrator, moderator, or owner
      if (!mounted) return;
      if (
        administrators.some((admin) => admin._id === _id) ||
        moderators.some((moderator) => moderator._id === _id) ||
        owners.some((owner) => owner._id === _id)
      ) {
        socket.on('getGalaxySubscriptionRequests', (requests) => {
          // Log the received subscription requests
          // console.log('Received subscription requests:', requests);

          // Play the notification sound only if the requests array is not null and not empty
          if (requests && requests.length > 0) {
            notificationSound.play();
          }

          // Directly set the state with the array of requests
          setSubscriptionRequests(requests);
        });
      }
      socket.on('subscriptionRequestAccepted', ({ galaxyId, requestId }) => {
        // Update state to 'Subscribed'
        setSubscriptionStatus('Subscribed');
      });
      socket.on('subscriptionRequestRejected', ({ galaxyId, requestId }) => {
        // Update state to 'Subscribe'
        setSubscriptionStatus('Subscribe');
      });
    }
    // Clean up the effect
    return () => {
      mounted = false;
      socket.off('getGalaxySubscriptionRequests');
      socket.off('subscriptionRequestAccepted');
      socket.off('subscriptionRequestRejected');
    };
  }, [socket, player, galaxy]);

  useEffect(() => {
    if (error) {
      console.error('Failed to fetch galaxy:', error);
    }
  }, [error]);

  useEffect(() => {
    let mounted = true;
    if (galaxy) {
      if (!mounted) return;

      let isMember = false;
      let isRequested = false;

      if (galaxy.members) {
        isMember = galaxy.members.includes(player.data.player._id);
        // console.log(galaxy.members, player.data.player._id, isMember);
        if (isMember) {
          // console.log('You are subscribed.');
          setSubscriptionStatus('Subscribed');
        }
      }

      if (galaxy.requests) {
        isRequested = galaxy.requests.some(
          (request) => request._id === player.data.player._id,
        );
        if (isRequested) {
          setSubscriptionStatus('Requested');
        }
      }

      if (!isMember && !isRequested) {
        setSubscriptionStatus('Subscribe');
      }

      setIsSubscribed(isMember);
    }
    return () => {
      mounted = false;
    };
  }, [galaxy, player]);

  // useEffect(() => {
  //   if (galaxy && galaxy.members) {
  //     setIsSubscribed(galaxy.members.includes(player.data.player._id));
  //   }
  // }, [galaxy, player]);

  useEffect(() => {
    if (galaxy) {
      handleYouTube(galaxy.coverMedia, setIsVideo, setIsTube);
    }
  }, [galaxy]);

  // Ref to keep track of the previous number of players excluding player.data.player._id
  const prevNumPlayersRef = useRef(0);

  useEffect(() => {
    let mounted = true;
    if (galaxy && galaxy._id) {
      socket.emit('addPlayerToGalaxyChat', {
        galaxyId: galaxy._id,
        playerId: player.data.player._id,
      });
    }

    socket.on('getGalaxyChat', (chat) => {
      if (chat) {
        if (!mounted) return;
        // console.log('chat: ', chat.players);
        setGalaxyChatOnlinePlayers(chat.players);

        // Get the current number of players excluding player.data.player._id
        const currNumPlayers = chat.players.filter(
          (p) => p !== player.data.player._id,
        ).length;

        // If the current number of players is greater than the previous number, play a sound
        if (currNumPlayers > prevNumPlayersRef.current) {
          // console.log('New player entered galaxy chat!');
          // playSound(enterGalaxyChatSound);
        }

        // Update prevNumPlayersRef
        prevNumPlayersRef.current = currNumPlayers;
      } else {
        console.log('Received null chat');
      }
    });

    return () => {
      mounted = false;
      if (galaxy && galaxy._id) {
        socket.emit('leaveGalaxyChat', {
          galaxyId: galaxy._id,
          playerId: player.data.player._id,
        });
      }
      socket.off('getGalaxyChat');
    };
  }, [galaxy, galaxy?._id, player, socket]); // This useEffect hook runs whenever galaxy or player changes

  useEffect(() => {
    let mounted = true;
    const handleShowVideo = (show) => {
      // console.log('Received show video:', show);
      // Check if the payload indicates to show the video
      if (show.showVideo === true) {
        if (!mounted) return;
        setShowVideo(true); // Update state to show the video
      }
    };

    socket.on('getShowVideo', handleShowVideo);

    return () => {
      // Cleanup the event listener on component unmount
      socket.off('getShowVideo', handleShowVideo);
      mounted = false;
    };
  }, [socket]);

  useEffect(() => {
    // console.log('isSubscribed:', isSubscribed);
    // console.log('Button Subscribed Class:', classes.buttonSubscribed);
  }, [isSubscribed, classes.buttonSubscribed]);
  // useEffect(() => {
  //   if (galaxy) console.log('Cover media URL:', galaxy.coverMedia);
  // }, [galaxy]);

  if (isLoading || !galaxy) {
    return <CircularProgress />;
  }

  const handleUnsubscribe = async () => {
    try {
      const response = await axiosConn.delete(
        `/galaxies/${galaxy._id}/remove/${player.data.player._id}`,
        // { id: player.data.player._id },
        {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        },
      );

      if (response.status === 200) {
        // console.log('Unsubscribed successfully.');
        setSubscriptionStatus('Subscribe');
        setIsSubscribed(false);
        setGalaxy((prevState) => ({
          ...prevState,
          members: prevState.members.filter(
            (memberId) => memberId !== player.data.player._id,
          ),
        }));
      }
    } catch (error) {
      console.error('Failed to unsubscribe:', error);
    }
  };

  const handleRequestSubscription = async () => {
    try {
      // Check if the user is already subscribed
      if (isSubscribed) {
        console.log('You are already subscribed.');
        return;
      }
      // console.log(
      //   'Sending subscription request.',
      //   galaxy._id,
      //   player.data.player._id,
      // );
      // If not subscribed, make the authenticated API call
      const response = await axiosConn.post(
        `/galaxies/${galaxy._id}/request`,
        {
          id: player.data.player._id,
          profilePhoto: player.data.player.profilePhoto,
          playerId: player.data.player.playerId,
        },
        { headers: { Authorization: `Bearer ${player.token}` } },
      );

      // Check if the request was successful
      if (response.status === 200) {
        // console.log('Subscription request sent successfully.');
        // Update the subscriptionStatus state variable
        setSubscriptionStatus('Requested');
        // console.log('Emitting sendGalaxySubscriptionRequests', {
        //   galaxyId: galaxy._id,
        //   _id: player.data.player._id,
        //   profilePhoto: player.data.player.profilePhoto,
        //   playerId: player.data.player.playerId,
        // });
        socket.emit('sendGalaxySubscriptionRequests', {
          galaxyId: galaxy._id,
          _id: player.data.player._id,
          profilePhoto: player.data.player.profilePhoto,
          playerId: player.data.player.playerId,
        });
      }
    } catch (error) {
      console.error('Failed to send subscription request:', error);
    }
  };

  const handleCancelRequest = async () => {
    try {
      // Make the authenticated API call
      const response = await axiosConn.delete(
        `/galaxies/${galaxy._id}/cancel/${player.data.player._id}`,
        // { id: player.data.player._id },
        { headers: { Authorization: `Bearer ${player.token}` } },
      );

      // Check if the request was successful
      if (response.status === 200) {
        console.log('Subscription request cancelled successfully.');
        // Update the subscriptionStatus state variable
        setSubscriptionStatus('Subscribe');
        setRequestHandled(true);
      }
    } catch (error) {
      console.error('Failed to cancel subscription request:', error);
    }
  };
  const handleAcceptRequest = async (requestId) => {
    try {
      console.log('Accepting request:', requestId);
      // Make the authenticated API call
      const response = await axiosConn.post(
        `/galaxies/${galaxy._id}/accept`,
        { id: requestId },
        { headers: { Authorization: `Bearer ${player.token}` } },
      );

      // Check if the request was successful
      if (response.status === 200) {
        // console.log('Subscription request accepted successfully.');
        // Update the subscriptionStatus state variable
        setSubscriptionStatus('Subscribed');
        socket.emit('acceptSubscriptionRequest', {
          galaxyId: galaxy._id,
          requestId,
        });
        setRequestHandled(true);
      }
    } catch (error) {
      console.error('Failed to accept subscription request:', error);
    }
  };

  const handleRejectRequest = async (requestId) => {
    try {
      console.log('Rejecting request:', requestId);
      // Make the authenticated API call
      const response = await axiosConn.delete(
        `/galaxies/${galaxy._id}/cancel/${requestId}`,
        { headers: { Authorization: `Bearer ${player.token}` } },
      );

      // Check if the request was successful
      if (response.status === 200) {
        console.log('Subscription request rejected successfully.');
        // Update the subscriptionStatus state variable
        setSubscriptionStatus('Subscribe');
        socket.emit('rejectSubscriptionRequest', {
          galaxyId: galaxy._id,
          requestId,
        });
      }
    } catch (error) {
      console.error('Failed to reject subscription request:', error);
    }
  };

  // isValidYoutubeUrl function, which checks if a URL is a valid YouTube URL
  function isValidYoutubeUrl(url) {
    const pattern =
      /^(http(s)?:\/\/)?((w){3}.)?(m.)?youtube(\.com)?(\/watch\?v=|\/v\/|\/embed\/|\/live\/|.be\/)?([A-Za-z0-9._%-]*)(\&\S+)?$/;
    return pattern.test(url);
  }

  const handleYoutubeSubmit = async () => {
    try {
      const response = await axiosConn.patch(
        `/galaxies/${galaxyId}`,
        {
          coverMedia: youtubeUrl,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${player.token}`,
          },
        },
      );

      if (response.status === 200) {
        // console.log(response.data.data.data);
        const updatedGalaxy = response.data.data.data;
        setGalaxy(updatedGalaxy);
        setYoutubeUrl(''); // Reset the input
        // console.log('Emitting sendShowVideo', { galaxyId, showVideo: true });
        // Emit an event to the server indicating the galaxy has been updated
        socket.emit('sendShowVideo', { galaxyId, showVideo: true });
      } else {
        throw new Error('Failed to update galaxy');
      }
    } catch (error) {
      console.error(error);
    }
  };

  // console.log(subscriptionRequests);
  // console.log(
  //   !(
  //     galaxy.owners.some((owner) => owner._id === player.data.player._id) ||
  //     galaxy.administrators.some(
  //       (admin) => admin._id === player.data.player._id,
  //     ) ||
  //     galaxy.moderators.some(
  //       (moderator) => moderator._id === player.data.player._id,
  //     )
  //   ),
  // );
  // console.log(subscriptionStatus);
  // console.log(setMmenuOpen);
  // console.log(player.data.player.role);
  // console.log(showVideo);
  return (
    <>
      <Topbar
        conversationsData={conversationsData}
        conversationsStatus={conversationStatus}
        refetchConversations={refetch}
        parent="GalaxyProfile"
        conversations={conversations}
        setConversations={setConversations}
        totalUnReadMessages={totalUnReadMessages}
        setTotalUnReadMessages={setTotalUnReadMessages}
        setMmenuOpen={setMmenuOpen}
      />
      {/* <Topbar setMmenuOpen={setMmenuOpen} /> */}
      {mobileView && <TopbarNotification />}

      <LoadingBar
        color="limegreen"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="profile">
        {!mobileView && <Sidebar />}
        <div className="profileRight">
          <div className="profileRightTop">
            {showVideo && (
              <div className="showVideoAdRotator">
                {showAd ? (
                  <div className={`adOverlay  fadeIn ${showAd ? 'show' : ''}`}>
                    <AdRotator onClose={() => setShowAd(false)} />
                    <div className="countdown">
                      {galaxy.name}: {countdown} secs
                    </div>
                  </div>
                ) : (
                  <div
                    className={`galaxyProfileCover fadeIn ${
                      !showAd ? 'show' : ''
                    }`}
                    onClick={() => {
                      coverInputRef.current.click();
                    }}
                  >
                    <div className={mmenuOpen ? 'no-overlay' : 'overlay'}></div>
                    <input
                      disabled={
                        !(
                          (galaxy.owners || []).some(
                            (owner) => owner._id === player.data.player._id,
                          ) ||
                          (galaxy.administrators || []).some(
                            (admin) => admin._id === player.data.player._id,
                          ) ||
                          (galaxy.moderators || []).some(
                            (moderator) =>
                              moderator._id === player.data.player._id,
                          )
                        )
                      }
                      type="file"
                      id="coverFile"
                      accept="image/*,video/*"
                      onChange={handleProfileAndCoverUpload({
                        parent: 'galaxy',
                        tag: 'cover',
                        setIsFetching,
                        setProgress,
                        setUploadPercentage,
                        setUpdateTrigger,
                        player,
                        galaxyId,
                        setGalaxy,
                      })}
                      style={{ display: 'none' }}
                      ref={coverInputRef}
                    />
                    <div className="mediaCoverContainer">
                      <MediaCover
                        isVideo={isVideo}
                        isTube={isTube}
                        mediaSource={galaxy?.coverMedia}
                        handleVideoLoad={handleVideoLoad}
                        parent="galaxyProfile"
                      />
                    </div>
                  </div>
                )}
              </div>
            )}{' '}
            {width <= 806 && (
              <GalaxyRightBarMobile
                onJgkTokenSumChange={handleJgkTokenSum}
                galaxy={galaxy}
                galaxyChatOnlinePlayers={galaxyChatOnlinePlayers}
                parent="galaxyProfile"
              />
            )}
            <div className="galaxyChatButtonDiv">
              {mobileView && (
                <button
                  className="galaxyChatButton"
                  onClick={() => {
                    {
                      setShowGalaxyChat(!showGalaxyChat);
                    }
                  }}
                >
                  {showGalaxyChat ? 'Hide Chat' : 'Galaxy Chat'}
                </button>
              )}
            </div>
            {(showGalaxyChat || !mobileView) && (
              <div
                className={
                  showVideo || mobileView
                    ? 'gridContainer'
                    : 'gridContainerTall'
                }
              >
                <div className="leftColumn">
                  <div>
                    {((galaxy.owners || []).some(
                      (owner) => owner._id === player.data.player._id,
                    ) ||
                      (galaxy.administrators || []).some(
                        (admin) => admin._id === player.data.player._id,
                      ) ||
                      (galaxy.moderators || []).some(
                        (moderator) => moderator._id === player.data.player._id,
                      )) && (
                      <div className="youtubeInputContainer">
                        <input
                          className="youTubeUrlInput"
                          type="text"
                          placeholder="Enter YouTube Go Live URL"
                          value={youtubeUrl}
                          onChange={(e) => setYoutubeUrl(e.target.value)}
                          onKeyDown={(e) => {
                            if (
                              e.key === 'Enter' &&
                              youtubeUrl &&
                              isValidYoutubeUrl(youtubeUrl)
                            ) {
                              handleYoutubeSubmit();
                            }
                          }}
                        />
                        <button
                          className="chatSubmitButton"
                          onClick={handleYoutubeSubmit}
                          disabled={
                            !youtubeUrl || !isValidYoutubeUrl(youtubeUrl)
                          }
                        >
                          Submit
                        </button>
                        {width >= 270 && (
                          <div className="galaxyProfileTooltip">
                            <InfoOutlinedIcon
                              className="sidebarIconTooltip"
                              fontSize="small"
                            />
                            <span className="galaxyProfileTooltiptext">
                              <p>Broadcast A Video! </p>
                              <p>- Enter Your Youtube Go Live Link.</p>
                              <p>- Or Enter Any Valid Youtube Video Link!</p>
                              <p>
                                - All <b>{galaxy.name}</b> Subscribers' Video
                                Above Will Update in Real-time.
                              </p>
                              <p>- Real-time or Pre-recorded Broadcasting.</p>
                              <p>
                                - Combined with Real-time Galaxy Group Chat.
                              </p>
                              <p>- Combined with a Galaxy Timeline of Posts.</p>
                              <br></br>
                              <p>
                                <b>{galaxy.name}</b> is a Communication &
                                Broadcast Network. Empower Your Galaxy!
                              </p>
                              <br></br>
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="galaxyChatBoxContainer">
                    <GalaxyChatBox
                      galaxyId={galaxy._id}
                      senderId={player.data.player._id}
                      senderProfilePhoto={player.data.player.profilePhoto}
                      senderPlayerId={player.data.player.playerId}
                      galaxyMembers={galaxy.members}
                      subscriptionStatus={subscriptionStatus}
                      setProgress={setProgress}
                      galaxyChatOnlinePlayers={galaxyChatOnlinePlayers}
                      isPlayerInRole={isPlayerInRole}
                    />
                  </div>
                </div>
                <div className="rightColumn">
                  <div className="profileInfo classes.parentContainer">
                    <div className="galaxyProfileInfoName">
                      <EasyEdit
                        value={galaxy.name}
                        type="text"
                        onSave={handleGalaxySave('name')}
                        saveOnBlur
                        attributes={{
                          name: 'name-input',
                          id: 'name',
                        }}
                        allowEdit={
                          (galaxy.owners || []).some(
                            (owner) => owner._id === player.data.player._id,
                          ) ||
                          (galaxy.administrators || []).some(
                            (admin) => admin._id === player.data.player._id,
                          ) ||
                          (galaxy.moderators || []).some(
                            (moderator) =>
                              moderator._id === player.data.player._id,
                          )
                        }
                      />
                    </div>

                    <div className="galaxyValueRow">
                      <div className="galaxyValueLabel">
                        galaxy market value:
                      </div>{' '}
                      <div className="galaxyJGKValue highlighted">
                        JGK
                        {Number(jgkTokenSum).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </div>
                    </div>
                    <div className="buttonContainer">
                      {isPlayerInRole ? (
                        <span>
                          <Button
                            variant="contained"
                            className={
                              subscriptionStatus === 'Subscribed'
                                ? classes.buttonSubscribed
                                : classes.buttonDefault
                            }
                            onClick={
                              subscriptionStatus === 'Subscribed'
                                ? handleUnsubscribe
                                : subscriptionStatus === 'Requested'
                                ? handleCancelRequest
                                : handleRequestSubscription
                            }
                            disabled={isPlayerInRole}
                          >
                            {player.data.player.role.charAt(0).toUpperCase() +
                              player.data.player.role.slice(1)}
                          </Button>
                        </span>
                      ) : (
                        <Button
                          variant="contained"
                          className={
                            subscriptionStatus === 'Subscribed'
                              ? classes.buttonSubscribed
                              : classes.buttonDefault
                          }
                          onClick={
                            subscriptionStatus === 'Subscribed'
                              ? handleUnsubscribe
                              : subscriptionStatus === 'Requested'
                              ? handleCancelRequest
                              : handleRequestSubscription
                          }
                          disabled={isPlayerInRole}
                        >
                          {subscriptionStatus}
                        </Button>
                      )}
                      <button
                        variant="contained"
                        className={
                          subscriptionStatus === 'Subscribed'
                            ? classes.buttonSubscribed
                            : classes.buttonDefault
                        }
                        onClick={() => {
                          setShowVideo(!showVideo);
                          setShowMoreGalaxyInfo(false);
                        }}
                      >
                        {showVideo ? 'Hide Video' : 'Show Video'}
                      </button>
                    </div>
                    {((galaxy.owners || []).some(
                      (owner) => owner._id === player.data.player._id,
                    ) ||
                      (galaxy.administrators || []).some(
                        (admin) => admin._id === player.data.player._id,
                      ) ||
                      (galaxy.moderators || []).some(
                        (moderator) => moderator._id === player.data.player._id,
                      )) &&
                      ['moderator', 'galaxyAdmin', 'admin'].includes(
                        player.data.player.role,
                      ) && (
                        <div>
                          {subscriptionRequests?.length > 0 && (
                            <h2 className="headerSpacing">
                              Subscription Requests
                            </h2>
                          )}
                          {subscriptionRequests?.length > 0 &&
                            subscriptionRequests.map((request) => (
                              <div
                                key={request._id}
                                className="requestContainer"
                              >
                                <DisplayPlayerJGK
                                  key={request._id}
                                  passedInPlayerId={request.playerId}
                                  parent="galaxyProfile"
                                />
                                <div className="buttonContainer">
                                  <button
                                    onClick={() =>
                                      handleAcceptRequest(request._id)
                                    }
                                  >
                                    Accept
                                  </button>
                                  <button
                                    onClick={() =>
                                      handleRejectRequest(request._id)
                                    }
                                  >
                                    Reject
                                  </button>
                                </div>
                              </div>
                            ))}
                        </div>
                      )}
                    {/* <div className="extra-info"> */}
                    {(showMoreGalaxyInfo ||
                      (width >= 807 && showMoreGalaxyInfo)) && (
                      <>
                        <div
                          className={'attributesContainer responsive-container'}
                        >
                          {galaxy &&
                            ['location', 'contactInfo', 'summary'].map(
                              (field) => (
                                <div className="easyEditWrapper" key={field}>
                                  <div className="fieldContainer">
                                    <span className="fieldName">{field}: </span>
                                    <EasyEdit
                                      value={galaxy[field]}
                                      type="text"
                                      onSave={handleGalaxySave(field)}
                                      saveOnBlur
                                      attributes={{
                                        name: `${field}-input`,
                                        id: field,
                                      }}
                                      className="galaxyProfileInfoDesc"
                                      allowEdit={
                                        (galaxy.owners || []).some(
                                          (owner) =>
                                            owner._id ===
                                            player.data.player._id,
                                        ) ||
                                        (galaxy.administrators || []).some(
                                          (admin) =>
                                            admin._id ===
                                            player.data.player._id,
                                        ) ||
                                        (galaxy.moderators || []).some(
                                          (moderator) =>
                                            moderator._id ===
                                            player.data.player._id,
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              ),
                            )}
                        </div>
                        <div className="centered-paragraph responsive-container">
                          <div className="easyEditWrapper">
                            <div className="fieldContainer">
                              <span className="fieldName">description: </span>
                              <EasyEdit
                                value={galaxy.description}
                                type="text"
                                onSave={handleGalaxySave('description')}
                                saveOnBlur
                                attributes={{
                                  name: 'description-input',
                                  id: 'description',
                                  style: {
                                    width: '100%',
                                  } /* This makes the input field take up the full width of its parent container */,
                                }}
                                className="galaxyProfileInfoDesc"
                                allowEdit={
                                  (galaxy.owners || []).some(
                                    (owner) =>
                                      owner._id === player.data.player._id,
                                  ) ||
                                  (galaxy.administrators || []).some(
                                    (admin) =>
                                      admin._id === player.data.player._id,
                                  ) ||
                                  (galaxy.moderators || []).some(
                                    (moderator) =>
                                      moderator._id === player.data.player._id,
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="galaxyProfileImages galaxyProfileImagesCentered responsive-container">
                          {['owners', 'moderators', 'administrators'].map(
                            (role) =>
                              (galaxy[role] || []).map((player) => (
                                <Link
                                  key={player._id}
                                  to={`/profile/${player.playerId}`}
                                >
                                  <div className="profileContainer centeredContainer">
                                    <img
                                      className="galaxyProfileOwnersProfileImg"
                                      src={player.profilePhoto}
                                      alt={`${player.name}'s profile`}
                                    />
                                    <p className="roleTitle">{role}</p>
                                  </div>
                                </Link>
                              )),
                          )}
                        </div>
                      </>
                    )}
                    {/* </div> */}
                    <a
                      className="show-more-link"
                      href="#"
                      onClick={(event) => {
                        event.preventDefault(); // Prevent the default action
                        handleShowMoreGalaxyInfo();
                      }}
                      style={{
                        color: '#8a2be2',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      {showMoreGalaxyInfo
                        ? 'show less \u25B2'
                        : 'more info \u25BC'}
                    </a>
                    {subscriptionStatus === 'Subscribe' && (
                      <WelcomeMessage playerId={player.data.player.playerId} />
                    )}
                  </div>
                  <div className={classes.adRotator}>
                    {!mobileView && <AdRotator />}
                  </div>
                </div>
                {/* <div className="rightColumn"> */}
              </div>
            )}
            <div className="profileRightBottom">
              {/* <div className="feedComponent"> */}
              <Feed
                className="galaxyFeed"
                parent={'galaxy'}
                galaxy={galaxy}
                playerId={player.data.player.playerId}
                subscriptionStatus={subscriptionStatus}
              />
              {/* </div> */}
              <GalaxyRightBar
                onJgkTokenSumChange={handleJgkTokenSum}
                galaxy={galaxy}
                galaxyChatOnlinePlayers={galaxyChatOnlinePlayers}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

//TODO: custom input field for description.
// const [isEditing, setIsEditing] = useState(false);
// const [description, setDescription] = useState(galaxy.description);

// const handleDescriptionChange = (event) => {
//   setDescription(event.target.value);
// };

// const handleDescriptionSave = () => {
//   handleGalaxySave('description')(description);
//   setIsEditing(false);
// };

// const handleDescriptionEdit = () => {
//   setIsEditing(true);
// };

// return (
//   <div className="fieldContainer">
//     <span className="fieldName">description: </span>
//     {isEditing ? (
//       <input
//         value={description}
//         onChange={handleDescriptionChange}
//         onBlur={handleDescriptionSave}
//       />
//     ) : (
//       <span onClick={handleDescriptionEdit}>{description}</span>
//     )}
//   </div>
// );
