import { useContext, useEffect, useState, useMemo } from 'react';
import ReactPlayer from 'react-player/lazy';
import InfiniteScroll from 'react-infinite-scroll-component';

import Topbar from '../../components/topbar/Topbar';
import Sidebar from '../../components/sidebar/Sidebar';
import Rightbar from '../../components/rightbar/Rightbar';
import CreateAd from '../../components/createAd/CreateAd';
import BuyJGKToken from '../../components/buyJGKToken/BuyJGKToken';
import AdRotator from '../../components/adRotator/AdRotator';
import { useMediaQuery } from 'react-responsive';
import { ViewPortContext, useViewport } from '../../context/ViewPortContext';
import axiosConn from '../../axiosConn';
import { AuthContext } from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import { CircularProgress } from '@material-ui/core';
import Table from '../../components/table/Table';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CashoutJGKToken from '../../components/cashoutJGKToken/CashoutJGKToken';
import DisplayPlayerJGK from '../../components/displayPlayerJGK/DisplayPlayerJGK';
import { useGetConversations } from '../../hooks/useGetConversations';
import { useGetJGKTransactions } from '../../hooks/useGetJGKTransactions';
import { format } from 'timeago.js';

import './jgktoken.css';
// import { useGetCurrPlayer } from '../../hooks/useGetCurrPlayer';

export default function JGKToken() {
  const { player } = useContext(AuthContext);

  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });
  const { width } = useContext(ViewPortContext);
  // const [currPlayer, setCurrPlayer] = useState({});
  const [isLoading, SetIsLoading] = useState(false);
  // const [jgkTransactions, setJGKTransactions] = useState({});

  //get current Player so we can show JGK and transactions.
  const [data, setData] = useState([]);
  const [cashoutJGK, setCashoutJGK] = useState(1);
  const [showJGKTutorial, SetShowJGKTutorial] = useState(true);
  const [conversations, setConversations] = useState([]);
  const [totalUnReadMessages, setTotalUnReadMessages] = useState(0);

  // const [isHovering, setIsHovering] = useState(false);

  const handleCashoutJGKToggle = () => {
    cashoutJGK === 0 ? setCashoutJGK(1) : setCashoutJGK(0);
  };

  // const handleMouseOver = () => {
  //   setIsHovering(true);
  //   console.log('handleMouseOver()');
  // };

  // const handleMouseOut = () => {
  //   setIsHovering(false);
  //   console.log('handleMouseOut()');
  // };

  const Date = ({ value, idx }) => {
    const utcDate = value;
    const localDate = new window.Date(utcDate);
    // console.log(localDate);
    return (
      <>
        <span key={idx} className="badge">
          {/* {format(value)} */}
          {localDate.toDateString()}
        </span>
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: 'JGK Credits',

        columns: [
          {
            Header: 'Amount (JGK)',
            accessor: 'amount',
            Cell: ({ cell: { value } }) => (
              <CurrencyFormat
                value={value}
                displayType={'text'}
                decimalScale={2}
                thousandSeparator={true}
                renderText={(value) => (
                  <div className="jgkCurrencyFormat">
                    {/* {currPlayer.playerId}{' '} */}
                    <span
                    //   style={
                    //     isLoading ? null : { 'background-color': '#42b72a' }
                    //   }
                    >
                      {isLoading ? (
                        <CircularProgress color="inherit" size="20px" />
                      ) : (
                        value
                      )}
                    </span>
                    {/* {' Available Balance.'} */}
                  </div>
                )}
                // prefix={'JGK$'}
              />
            ),
          },
          {
            Header: 'Date',
            accessor: 'createdAt',
            Cell: ({ cell: { value } }) => <Date value={value} />,
          },
          {
            Header: 'Type',
            accessor: 'type',
            Cell: ({ cell: { value } }) => (
              <div className="jgkTokenType">
                <span>
                  {isLoading ? (
                    <CircularProgress color="inherit" size="20px" />
                  ) : (
                    <>
                      {value}
                      {/* {value === 'Payment' ? (
                        <div className="tooltipJGK">
                          <InfoOutlinedIcon
                            className="sidebarIconTooltip"
                            fontSize="small"
                            // color={shareJG === 1 ? 'black' : 'secondary'}
                          />
                          <span className="tooltipJGKtext">
                            <p>JGK: JG Klout </p>
                            <br></br>
                            <p>
                              - As a JG Player and in Life everything Counts.{' '}
                            </p>
                            <p>- Everything you do matters. You Matter!</p>
                            <p>- You create Everything!</p>
                            <br></br>
                            <p>- This game is your life, see you at the top!</p>
                            <br></br>
                            <p>
                              Start accumulating JGK and Stay tuned for ways to
                              leverage your JGK balance in your favor.
                            </p>
                            <br></br>
                            <p>See your JGK Credits below</p>
                          </span>
                        </div>
                      ) : null} */}
                    </>
                  )}
                </span>
              </div>
            ),
          },
          {
            Header: 'Sender',
            accessor: 'sender',
            Cell: ({ cell: { value } }) => (
              <div className="jgkTokenType">
                <span>
                  {isLoading ? (
                    <CircularProgress color="inherit" size="20px" />
                  ) : (
                    <>{value}</>
                  )}
                </span>
              </div>
            ),
          },
        ],
      },
    ],
    [],
  );

  // const onSuccess = (data) => {
  //   // console.log('data: ', data);
  // };

  // const onError = (err) => {
  //   // console.log('err: ', err);
  // };

  // const { data: data2, status } = useGetCurrPlayer(onSuccess, onError, player);
  // // console.log(data);

  // useEffect(() => {
  //   let mounted = true;
  //   //set conversations
  //   if (status === 'success') {
  //     if (!mounted) return;
  //     setCurrPlayer(data2);
  //   }
  //   return () => {
  //     mounted = false;
  //   };
  // }, [status, data2]);

  // useEffect(() => {
  //   let mounted = true;

  //   const getJGKTransactions = async () => {
  //     try {
  //       SetIsLoading(true);
  //       const res1 = await axiosConn.get(
  //         `/jgkTokenTransactions/${player.data.player._id}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${player.token}`,
  //           },
  //         },
  //       );
  //       // setJGKTransactions(res1);
  //       // console.log(
  //       //   res1.data.sort((a, b) => {
  //       //     // console.log(a, b);
  //       //     return new window.Date(b.createdAt) - new window.Date(a.createdAt);
  //       //   }),
  //       // );
  //       // setData(res1.data);
  //       if (!mounted) return;

  //       setData(
  //         res1.data.sort((a, b) => {
  //           // console.log(a, b);
  //           return new window.Date(b.createdAt) - new window.Date(a.createdAt);
  //         }),
  //       );

  //       SetIsLoading(false);
  //     } catch (err) {
  //       console.log(err);
  //       SetIsLoading(false);
  //     }
  //   };
  //   getJGKTransactions();

  //   return () => {
  //     mounted = false;
  //   };
  // }, [player.data.player._id, player.token, player.data.player.playerId]);

  const onSuccess = (newData) => {
    setData(newData);
    SetIsLoading(false);
  };

  const onError = (err) => {
    console.log(err);
    SetIsLoading(false);
  };

  const {
    data: jgkData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useGetJGKTransactions(player, onSuccess, onError);

  const onSuccess2 = (data) => {
    // console.log('data: ', data);
  };

  const onError2 = (err) => {
    // console.log('err: ', err);
  };

  const {
    data: conversationsData,
    isSuccess,
    status: conversationStatus,
    refetch,
  } = useGetConversations(onSuccess2, onError2, player);
  // console.log(data);

  useEffect(() => {
    //set conversations
    if (isSuccess) {
      // console.log(isSuccess);
      setConversations(conversationsData);
    }
  }, [conversationsData, isSuccess]);

  const flattenedData = jgkData?.pages.flat() || [];

  return (
    <>
      {/* <Topbar parent="JGKToken" /> */}
      <Topbar
        conversationsData={conversationsData}
        conversationsStatus={conversationStatus}
        refetchConversations={refetch}
        // parent="Messenger"
        conversations={conversations}
        setConversations={setConversations}
        totalUnReadMessages={totalUnReadMessages}
        setTotalUnReadMessages={setTotalUnReadMessages}
        parent="JGKToken"
      />
      <div className="jgktokenContainer">
        {!mobileView && <Sidebar />}
        <div className="createJGTokenWrapper">
          {/* <DisplayPlayerJGK /> */}
          <div
            className={
              !mobileView ? 'jgkCashoutBuyToggle' : 'memePostToggleMobile'
            }
          >
            <button
              className={cashoutJGK ? 'jgkBuyButton' : 'jgkCashoutButton'}
              onClick={handleCashoutJGKToggle}
              id="sort"
            >
              <div className="buttonDiv">
                <CurrencyExchangeOutlinedIcon
                  size="small"
                  className="currencyOutlineIcon"
                />
                {!cashoutJGK ? 'Cashout JGK' : 'Purchase JGK'}
              </div>
            </button>
          </div>
          {showJGKTutorial && (
            <div className="jgkTokenBottom">
              <div className="jgkTokenWrapperInfo">
                <ReactPlayer
                  autoPlay={true}
                  loop={true}
                  muted={true}
                  width="100%"
                  playing={false}
                  controls={true}
                  playbackRate={1.25}
                  url={
                    'https://res.cloudinary.com/joegalaxy/video/upload/v1688862323/CashoutJGKToGiftCardv1_hsy6nd.mp4'
                  }
                />
                <div className="jgkTokenDescription">
                  <p>
                    <i>
                      Players earn <b>Joe Galaxy Klout, "JGK"</b>, on all
                      actions: posting, memeing, commenting, etc. <b>JGK</b> can
                      be <b>sent to other players</b> on the platform and{' '}
                      <b>
                        can be cashed out to global mobile airtime topups & gift
                        cards
                      </b>
                      , such as major brands:{' '}
                      <b>Amazon, Walmart, Virtual Visa & MasterCard</b>, which
                      can be put onto your Apple Pay and used anywhere. So don't
                      just get paid with a like and let "them" make billions
                      with your content, <b>earn JGK Today</b>, Now!
                    </i>
                  </p>
                  <button
                    className="closeButton"
                    onClick={() => SetShowJGKTutorial(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
          {cashoutJGK ? (
            <CashoutJGKToken
              showJGKTutorial={showJGKTutorial}
              SetShowJGKTutorial={SetShowJGKTutorial}
            />
          ) : (
            <BuyJGKToken />
          )}
          {/* create get jgkToken Transactions and show in a grid layout. */}
          <div className="jgkTokenBottom">
            <div className="jgkTokenWrapper">
              <div className="jgkTokenTable">
                {isLoading ? (
                  <CircularProgress color="inherit" size="20px" />
                ) : (
                  <Table
                    columns={columns}
                    data={flattenedData}
                    fetchNextPage={fetchNextPage}
                    hasNextPage={hasNextPage}
                    isFetchingNextPage={isFetchingNextPage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {!mobileView && <Rightbar />}
      </div>
    </>
  );
}
