import styled from 'styled-components';

export const Box = styled.div`
  padding: 13px 60px;
  background: #f0f2f5;
  position: absolute;
  bottom: 0;
  width: 100%;

  @media (max-width: 1280px) {
    width: 88%;
  }
  @media (max-width: 1024px) {
    width: 88%;
  }
  @media (max-width: 1000px) {
    padding: 13px 0px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
  /* background: red; */
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 80px;

  @media (max-width: 1570px) {
    margin-left: 190px;
  }

  @media (max-width: 1024px) {
    margin-left: 180px;
  }

  @media (max-width: 912px) {
    margin-left: 160px;
  }

  @media (max-width: 820px) {
    margin-left: 130px;
  }
  @media (max-width: 540px) {
    margin-left: 55px;
  }

  @media (max-width: 414px) {
    margin-left: 30px;
    padding: 13px 30px;
  }
  @media (max-width: 412px) {
    margin-left: 30px;
  }

  @media (max-width: 393px) {
    margin-left: 30px;
  }
  @media (max-width: 375px) {
    margin-left: 30px;
    padding: 1px 30px;
  }
  @media (max-width: 360px) {
    margin-left: 30px;
    padding: 1px 30px;
  }
  @media (max-width: 280px) {
    margin-left: 20px;
  }
`;

export const Row = styled.div`
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(185px, 1fr)); */
  grid-template-columns: auto auto;
  grid-gap: 20px;

  @media (max-width: 1000px) {
    /* grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */
    grid-template-columns: auto auto;
  }
`;

export const FooterLink = styled.a`
  color: #fff;
  margin-bottom: 15px;
  font-size: 15px;
  text-decoration: none;

  &:hover {
    color: #2e801d;
    transition: 200ms ease-in;
  }
  @media (max-width: 1280px) {
    font-size: 11px;
  }

  @media (max-width: 375px) {
    font-size: 9px;
  }

  @media (max-width: 360px) {
    font-size: 10px;
  }
  @media (max-width: 280px) {
    font-size: 8px;
  }
`;
export const PrivacyLink = styled.a`
  color: ##8a2be2;
  margin-bottom: 15px;
  font-size: 11px;
  text-decoration: none;

  &:link {
    color: #8a2be2;
    transition: 200ms ease-in;
  }
  &:visited {
    color: ##8a2be2;
    transition: 200ms ease-in;
  }
  &:hover {
    color: #2e801d;
    transition: 200ms ease-in;
  }

  @media (max-width: 1280px) {
    font-size: 11px;
  }

  @media (max-width: 375px) {
    font-size: 9px;
  }

  @media (max-width: 360px) {
    font-size: 10px;
  }
  @media (max-width: 280px) {
    font-size: 8px;
  }
`;

export const Heading = styled.p`
  font-size: 18px;
  color: #8a2be2;
  margin-bottom: 10px;
  font-weight: bold;

  @media (max-width: 1280px) {
    font-size: 13px;
  }

  @media (max-width: 1024px) {
    font-size: 15px;
  }

  @media (max-width: 375px) {
    font-size: 11px;
  }
  @media (max-width: 360px) {
    font-size: 11px;
  }
  @media (max-width: 280px) {
    font-size: 11px;
  }
`;
