import './comment.css';
import { useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../../context/AuthContext';
import axiosConn from '../../axiosConn';
import { format } from 'timeago.js';
import { useMediaQuery } from 'react-responsive';
import Like from '../like/Like';
import ModalImage from 'react-modal-image';
import preciseFormat from '../../utils/preciseFormat';
import DOMPurify from 'dompurify';
import { Link } from 'react-router-dom';
import { SocketContext } from '../../context/socket';

export default function Comment({ postId, memeId, comment, parent }) {
  const sanitizedComment = DOMPurify.sanitize(comment.comment);
  const socket2 = useContext(SocketContext);

  const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  const [like, setLike] = useState(
    comment && comment.likes ? comment.likes.length : 0,
  );
  const [isLiked, setIsLiked] = useState(false);
  //const [isLoading, setIsLoading] = useState(false);
  const { player } = useContext(AuthContext);
  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });
  const imgRef = useRef(null);

  //console.log(like);
  // console.log(comment);
  useEffect(() => {
    setIsLiked(
      comment && comment.likes
        ? comment.likes.includes(player.data.player.playerId)
        : false,
    );
  }, [comment, player.token, player.data.player.playerId]);

  const likeHandler = async () => {
    // console.log(player);
    //console.log(comment.likes.length);
    try {
      if (parent === 'Meme') {
        const response = await axiosConn.put(
          '/memes/comments/' + memeId + '/like/' + comment._id,
          {
            playerId: player.data.player.playerId,
          },
          {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          },
        );
        // // Emit the updated post to the server
        // socket2.emit('updatePost', { updatedPost: response.data.data.post });
      } else {
        const response = await axiosConn.put(
          '/posts/comments/' + postId + '/like/' + comment._id,
          {
            playerId: player.data.player.playerId,
          },
          {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          },
        );
        // console.log(response.data.data.post);
        // Emit the updated post to the server
        socket2.emit('updatePost', { updatedPost: response.data.data.post });
      }
    } catch (err) {}
    setLike(isLiked ? like - 1 : like + 1);
    setIsLiked(!isLiked);
  };

  useEffect(() => {
    // console.log('sanitizedComment', sanitizedComment);
    const links = document.querySelectorAll(
      '.commentText a, .commentTextMobile a',
    );
    // console.log('links', links);
    links.forEach((link) => {
      // console.log('link', link);
      link.addEventListener('click', (e) => {
        e.stopPropagation(); // Prevent any parent handlers from interfering
      });
    });
  }, [sanitizedComment]);

  const linkify = (text) => {
    const urlPattern =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text.replace(
      urlPattern,
      '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>',
    );
  };

  const linkifiedComment = linkify(sanitizedComment);

  return (
    <>
      <div className="comment">
        <Link to={`/profile/${comment.commentBy.playerId}`}>
          <img
            src={
              comment.commentBy.profilePhoto
                ? comment.commentBy.profilePhoto
                : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
            }
            className={
              !mobileView ? 'commentProfileImage' : 'commentProfileImageMobile'
            }
            alt=""
            loading="lazy"
          />
        </Link>
        <div className="commentColumn" ref={imgRef}>
          <div className="commentWrap">
            <div className={!mobileView ? 'commentName' : 'commentNameMobile'}>
              {comment.commentBy.playerId}
            </div>
            <div
              className={!mobileView ? 'commentText' : 'commentTextMobile'}
              dangerouslySetInnerHTML={{ __html: linkifiedComment }}
            ></div>
          </div>

          {comment.image && (
            <ModalImage
              small={comment.image}
              large={comment.image}
              alt=""
              className="commentImage"
              hideDownload={true}
            />
          )}
          <div className="commentActions">
            {/* <span>Like</span> */}
            {/*   <span>Reply</span> */}
            <span>
              <span className={!mobileView ? 'postDate' : 'postDateMobile'}>
                {preciseFormat(comment.commentAt)}
              </span>
            </span>
          </div>
        </div>

        <div className="channelBottom">
          <Like
            imgRef={imgRef}
            text="+2 JGK, Yay!"
            likes={like}
            likesProfileImgs={[]}
            isLiked={isLiked}
            likeHandler={likeHandler}
            mobileView={mobileView}
            PF={PF}
            title={isLiked ? 'You liked this' : ''}
            textOffset={mobileView ? -15 : -20}
            // width={width}
          />
          <div className="channelBottomRight"></div>
        </div>
      </div>
    </>
  );
}
